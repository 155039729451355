import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Switch, Route, HashRouter, BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga';

import App from "./App";
import Products from './Products';

ReactGA.initialize('UA-159244758-1');
ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/:language" component={App} />
      <Route exact path="/products/:language" component={Products} />
    </Switch>
  </HashRouter>,

  document.getElementById("root")
);
