import React from "react";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import "./Product.css";
import ReactGA from 'react-ga';

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = { quantity: this.props.product.quantity, timer: 300 };
   
    this.emailInput = React.createRef();
  }

  componentWillMount(){
    ReactGA.pageview(window.location.pathname + window.location.search); 
    if(this.props.keyu === 0 && this.props.firstElementCursor === true){
      this.state.focus = true;
      this.props.changeElementCursor();
    }
  }

  componentDidMount(){
    if(this.state.focus === true){
      this.emailInput.current.focus();
     
    }
   
  }

  componentDidUpdate(){
   
    
    if (this.props.product.triggerQuantity !== "") {
      if (this.state.quantity >= parseInt(this.props.product.triggerQuantity)) {
        if (this.props.language === "hindi") {
          // swal({
          //   title: "An error occured",
          //   text: this.props.product.hindiTriggerQuantityError,
          //   button: "Okay, got it.",
          // });
          setTimeout(() => {
            this.props.showPopupHandler(this.props.product.hindiTriggerQuantityError);
          },500);
          
        } else {
          // swal({
          //   title: "An error occured",
          //   text: this.props.product.triggerQuantityError,
          //   button: "Okay, got it.",
          // });
          setTimeout(() => {
          this.props.showPopupHandler(this.props.product.triggerQuantityError);
          },500);
        }

        this.props.popupAddHandler(this.props.keyu);
      }
    }
  }


  onAddHandler = () => {
    ReactGA.event({category: this.props.product.name, action: "click", label: "Add", value: this.props.product.price});
    // ga('send', 'event', this.props.product.name, 'event', 'add', 1);
    if (this.props.spent + this.props.product.price <= this.props.fixedAmount) {
      if (
        this.props.product.maxQuantity == 0 ||
        this.state.quantity < this.props.product.maxQuantity
      ) {
        this.setState({ quantity: parseInt(this.state.quantity) + 1 });
        this.props.changeHandler(
          "add",
          this.props.product.price,
          1,
          this.props.keyu
        );
       
      }
    }
  };

  onSubtractHandler = () => {
    ReactGA.event({category: this.props.product.name, action: "click", label: "Subtract", value: this.props.product.price});
    if (this.state.quantity > 0) {
      this.setState({ quantity: parseInt(this.state.quantity) - 1 });
      this.props.changeHandler(
        "subtract",
        this.props.product.price,
        1,
        this.props.keyu
      );
      
    }
  };

  onAddHandlerDown = (e) => {
    clearInterval();
  };

  

  onChangeHandler = (e) => {
    const old_quantity = this.state.quantity;
    const value = e.target.value;
    
    var maxQ = 0;

    if (
      this.props.product.maxQuantity !== "" ||
      this.props.product.maxQuantity > 0
    ) {
      maxQ = parseInt(this.props.product.maxQuantity);
    }

    if (value >= 0 && (value <= maxQ || maxQ === 0)) {
      if (this.state.old_quantity > value) {
        //sell
        this.props.changeHandler(
          "subtract",
          (old_quantity - value) * this.props.product.price,
          old_quantity - value,
          this.props.keyu
        );
        ReactGA.event({category: this.props.product.name, action: "change", label: "Subtract", value: (old_quantity - value) * this.props.product.price});
        this.setState({ quantity: value });

      } else {
        //buy
        if (
          this.props.spent + (value - old_quantity) * this.props.product.price <
          this.props.fixedAmount
        ) {
          this.props.changeHandler(
            "add",
            (value - old_quantity) * this.props.product.price,
            value - old_quantity,
            this.props.keyu
          );
          ReactGA.event({category: this.props.product.name, action: "change", label: "Add", value: (value - old_quantity) * this.props.product.price});

          this.setState({ quantity: value });
        } else {
          this.setState({ quantity: old_quantity });
        }
      }
      // this.props.changeHandler(this.state.quantity, this.props.price);
    }else if(value >= maxQ && maxQ !== 0){
       
        //buy
        if (
            this.props.spent + (this.props.product.maxQuantity - old_quantity) * this.props.product.price <
            this.props.fixedAmount
          ) {
            this.props.changeHandler(
              "add",
              (this.props.product.maxQuantity - old_quantity) * this.props.product.price,
              this.props.product.maxQuantity - old_quantity,
              this.props.keyu
            );
            this.setState({quantity: this.props.product.maxQuantity})
        
          } else {
            this.setState({ quantity: old_quantity });
          }
  
          
         
    }
  };

  render() {
    let url = './';
    if(process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000/'
    }
    if(process.env.NODE_ENV === 'production') {
      url = 'https://mainbhiambani.com/'
    }
    
    return (
      <div className="product">
        <div className="image">
          <img
            className="productImage"
            src={url + 'images/' + this.props.product.image}
            width="120px"
          />
        </div>

        <div className="description">
          <h2 className="title">
            {this.props.language === "hindi"
              ? this.props.product.hindiName
              : this.props.product.name}
          </h2>
          <p className="subtitle">
            {this.props.language === "hindi"
              ? this.props.product.hindiSubtitle
              : this.props.product.subtitle}
          </p>

          <div class="bottom">
            <h5 className="price">
              <NumberFormat
                value={this.props.product.price}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
              />
            </h5>
            <div className="actionButtons">
              <button onClick={this.onSubtractHandler} className="subtract">
                <i class="fas fa-minus"></i>
              </button>
              <input
                onChange={this.onChangeHandler}
                ref={this.emailInput}
                value={this.props.product.quantity}
                type="text"
                className="quantity"
              />
              <button
                onMouseDown={this.onAddHandlerP}
                onClick={this.onAddHandler}
                className="add"

              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
