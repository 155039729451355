import React from 'react';
import {Link} from 'react-router-dom';
import Odometer from 'react-odometerjs';
import ReactGA from 'react-ga';
import './App.css';


class App extends React.Component {
   constructor(props){
      super(props);

      this.state = {
         odometerValue: 0,
         language: this.props.match.params.language || 'english',
         text: {
            logo: {
               english: "Main Bhi Ambani",
               hindi: "में भी अंबानी"
            },
            logo_2:{
               english: "Jai Shree Ambani",
               hindi: "जय श्री अंबानी"
            },
            subtitle: {
               english: "Jio Ambani's Life",
               hindi: "अब जियो अंबानी की जिंदगी"
            },
            mainText: {
               english: "We don’t like to show off our money, but no one said anything about showing off someone else’s money",
               hindi: "हम अपना पैसा नहीं दिखाना चाहते हैं, लेकिन किसी ने किसी और के पैसे दिखाने के बारे में कुछ नहीं कहा"
            },
            buttonText: {
               english: "Start Spending",
               hindi: "खर्च करना शुरू करें"
            }
         }
      }
   }

   componentDidMount(){
      ReactGA.initialize('UA-159244758-1');
      ReactGA.pageview(window.location.pathname +  
         window.location.search); 
      this.setState({odometerValue: 4000000000000})
   }

   languageChange = () => {
      if(this.state.language === 'english'){
         this.setState({language: 'hindi'});
      }else{
         this.setState({language: 'english'});
      }
   }

    render() {
      var language = '';
      let url = './';
      if(process.env.NODE_ENV === 'development') {
        url = 'http://localhost:3000/'
       
      }
      if(process.env.NODE_ENV === 'production') {
        url = 'https://mainbhiambani.com/'
      }
      

      if(this.state.language == 'english'){
         language = 'हिन्दी';
      }else{
         language = 'English'
      }
       return (
          <div className = "container app bodyFlex">
             <header className = "headerHome">
            
               <div className = "title">
                  <div className = "logoImage">
                     <img src = {url + "images/logo.png"} height = "45px" />
                  </div>
                  <div className = "logoText">
                     <h1>{this.state.text['logo'][this.state.language]}</h1>
                     <h2>{this.state.text['logo_2'][this.state.language]}</h2>
                  </div>
              
               </div>
             </header>

             <div className = "mainText">
            <h2>{this.state.text.subtitle[this.state.language]}</h2>

               <p>
                  {this.state.text.mainText[this.state.language]}
               </p>
                   
            <div className = "center">
            <Odometer value={this.state.odometerValue} duration = "500" format="(d,dd,dd,dd,dd,dd,ddd)" />
            </div>
             </div>
        
            

         <div className = "bottomPage">
            <div className = "center">
            <Link to = {"/products/" + this.state.language}><button class = "startSpending">
               {this.state.text.buttonText[this.state.language]}
               </button></Link>
            </div>
             
             <h4 className = "language"><a onClick = {this.languageChange} href = "#">{language}</a></h4>
         </div>
          
          </div>
       )
    }
 }

 export default App;