import React from 'react';

import './Popup.css';

const Popup = (props) => {
    let url = './';
    if(process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000/'
    }
    if(process.env.NODE_ENV === 'production') {
      url = 'https://www.mainbhiambani.com/'
    }
    
    return(
        <div className = "overlay">
            <div className = "popupContainer">
               <section className = "popupHeading">
                <img src= {url + 'images/feeling.png'} height = "23px" />
                <h2>Notification</h2>
               </section>

               <section className = "popupText">
                <p>{props.text}</p>
               </section>

               <section className = "popupButton">
                <button onClick = {props.onPopupAction} className = "popupActionButton">Okay, got it.</button>
               </section>
            </div>
        </div>
        
        
    )
}

export default Popup;