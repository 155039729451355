import React from "react";
import NumberFormat from "react-number-format";

import Popup from './Popup';
import Product from "./Product";
import Button from "./button";
import "./Products.css";

import { list } from "./list";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 4000000000000,
      fixedAmount: 4000000000000,
      spent: 0,
      items: 0,
      viewItems: false,
      language: this.props.match.params.language,
      text: {
        viewItems: {
          english: "View Items",
          hindi: "आइटम देखें",
        },
        share: {
          english: "Share",
          hindi: "शेयर करे",
        },
      },
      firstElementCursor: true,
      scrollPosition: 0
    };
    this.state.products = list;
  }



  shareText = () => {
    
    var products = [...this.state.products];

   var user_products = products.filter(el => {
     return el.quantity > 0;
   })

   if(user_products.length >= 3){
    var last_three_elemets = user_products.slice(-3);
   }else if(user_products.length > 0 && user_products.length < 3){
     var last_three_elemets = user_products;
   }else{
     var last_three_elemets = [];
   }

   if(this.state.language !== 'hindi'){
    var text = "I just spent " + (this.state.fixedAmount - this.state.amount) + " Rs of Ambani's money on: \n";

    last_three_elemets.map(et => {
      text += et.quantity + ' x ' + et.name + '\n';
    })
  
   text += "and many more unbelievable things, now you can also JIO Ambani's life at:";

   }else{
    var text = "मैंने अभी अंबानी के " + (this.state.fixedAmount - this.state.amount) + " रुपये खर्च किये: \n";

    last_three_elemets.map(et => {
      text += et.quantity + ' x ' + et.hindiName + '\n';
    })
  
   text += "और कई सारे अद्बुध चीज़ें खरीदने में, अब आप भी जी सक्ते हैं अम्बानी की ज़िन्दगी:";
   }
  
  return text;
  }

  onChangeHandler = (type, price, items, key) => {
    if (this.state.amount >= 0) {
      if (type === "subtract") {
        const products = [...this.state.products];
        products[key].quantity = this.state.products[key].quantity - items;

        this.setState({
          amount: this.state.amount + parseInt(price),
          spent: this.state.spent - parseInt(price),
          items: parseInt(this.state.items) - items,
          products: products,
        });
      } else if (type == "add") {
        const products = [...this.state.products];
        products[key].quantity = this.state.products[key].quantity + items;
        this.setState({
          amount: this.state.amount - parseInt(price),
          spent: this.state.spent + parseInt(price),
          items: parseInt(this.state.items) + items,
          products: products,
        });
      }
    }
  };

  viewItemsHandler = () => {
    if(this.state.viewItems === false){
      this.setState({
        viewItems: !this.state.viewItems,
        scrollPosition: window.pageYOffset
      });
    }else{
      this.setState({
        viewItems: !this.state.viewItems
      },() => {
        window.scrollTo(0, parseInt(this.state.scrollPosition))
      })

      
    }
    
  };

  onLanguageChange = (lan) => {
    this.setState({
      language: lan,
    });
  };
  
  popupAddHandler = (key) => {
    const products = [...this.state.products];
    products[key].triggerQuantity = '';
    this.setState({
      products: products
    })
  }

  changeMaxHandler = (key) => {
    const products = [...this.state.products];
    products[key].quantity = this.state.products[key].maxQuantity;

    this.setState({
      products: products
    })
  }

  showPopupHandler = (text) => {
    this.setState({
      showPopup: true,
      popupText: text
    })
  }

  onPopupAction = () => {
    this.setState({
      showPopup: false,
      popupText: ''
    })
  }

  changeElementCursor = () => {
    this.setState({
      firstElementCursor: false
    })
  }
  render() {
    let url = 'https://mainbhiambani.com/';
    if(process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000/'
    }
    if(process.env.NODE_ENV === 'production') {
      url = 'https://mainbhiambani.com/'
    }
    var container;
    var language;
    var name;
    var popup = '';
    var inspired_by;
    var contact = '';
    var shareLanguage = '';

    var backArrow;

    if (this.state.viewItems === false) {
      backArrow = "";
      container = (
        <section className="productsContainer">
          {this.state.products.map((product, i) => {
            return (
              <div className="productS" key={i}>
                <Product
                  fixedAmount={this.state.fixedAmount}
                  language={this.state.language}
                  keyu={i}
                  changeHandler={this.onChangeHandler}
                  amount={this.state.amount}
                  spent={this.state.spent}
                  product={product}
                  popupAddHandler = {this.popupAddHandler}
                  changeMaxHandler = {this.changeMaxHandler}
                  showPopupHandler = {this.showPopupHandler}
                  firstElementCursor=  {this.state.firstElementCursor}
                  changeElementCursor = {this.changeElementCursor}
                />
              </div>
            );
          })}


        </section>
      );
      contact = (
        <div className = "contact">
        <h3>That's all folks!</h3>
        <p>For brand integration and collaborations: Reach us at <a href = "mailto: hello@mainbhiambani.com">hello@mainbhiambani.com</a></p>
      </div>
      );
      inspired_by = (
        <div className="inspiredBy">
          <h3>
            Inspired by{" "}
            <a target="_blank" href="https://neal.fun/">
              Neal.fun
            </a>
          </h3>
        </div>
      );
    } else {
      inspired_by = "";
      backArrow = <div onClick={this.viewItemsHandler} className="backArrow">&lt;</div>;
      container = (
        <section className="itemCart_container">
          {this.state.products.map((product, i) => {
            if (product.quantity > 0) {
              return (
                <div className="itemCart">
                  <div className="itemCart_left">
                    <div className="itemCart_image">
                      <img
                        src={url + "images/" + product.image}
                        width="50px"
                      />
                    </div>
                    <div className="itemCart_description">
                      <h5>
                        {this.state.language === "hindi"
                          ? product.hindiName
                          : product.name}
                      </h5>
                      <p>x{product.quantity}</p>
                    </div>
                  </div>

                  <div class="itemCart_right">
                    <h5>
                      <NumberFormat
                        value={product.price * product.quantity}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </h5>
                  </div>
                </div>
              );
            }
          })}

          
        </section>
      );
    }

    if (this.state.language === "english") {
      shareLanguage = 'Share';
      language = (
        <h4>
          <a onClick={() => this.onLanguageChange("hindi")}>हिन्दी</a>
        </h4>
      );
    } else if (this.state.language === "hindi") {
      shareLanguage = "शेयर";
      language = (
        <h4>
          <a onClick={() => this.onLanguageChange("english")}>English</a>
        </h4>
      );
    }

    if(this.state.showPopup === true){
      popup = (
        <Popup onPopupAction = {this.onPopupAction} text = {this.state.popupText} />
      )
    }else{
      popup = ''
    }
   
    
    return (
      <div className="container">
       {popup}
        <div className = "sticky">
        <section className="headerProducts">
          <div class="left" >
            {backArrow}
            <div className="logo">
              <img src={url+ "images/logo.png"} height="45px" />
            </div>
            <div className="name_money">
              <div className="name">
                <h1>Main bhi Ambani</h1>
              </div>
              <div className="money">
                <h3>
                  <NumberFormat
                    value={this.state.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    prefix={"₹"}
                  />
                </h3>
              </div>
            </div>
          </div>

          <div class="center_header">
            <div className="money">
              <h3>
                <NumberFormat
                  value={this.state.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                />
              </h3>
            </div>
          </div>
          <div className="language">{language}</div>
        </section>
      
        </div>

       
        <hr className = "horizontal_divider" />
        
        <div className="containerBody">
          {container}
         
         {contact}

         {inspired_by}
         
          </div>


        <section className="bottomBarWrapper">
          <div className = "bottomBar">
          <div className="summary">
            <h3>
              <NumberFormat
                value={this.state.spent}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
              />
            </h3>
            <p>on {this.state.items} items</p>
          </div>

          <div className="callToActions">
            <button onClick={this.viewItemsHandler} className="viewItems">
              {this.state.text.viewItems[this.state.language]}
            </button>
            {/* <button className="share">{this.state.text.share[this.state.language]}</button> */}
            <Button
              config={{
                params: {
                  title: "Main Bhi Ambani: Jio Ambani's Life",
                  text: this.shareText(),
                  url: "https://www.mainbhiambani.com/#/" + (this.state.language === 'hindi' ? 'hindi' : ''),
                },
                /* tslint:disable-next-line:no-console */
                onShareSuccess: () => console.log("Success"),
                /* tslint:disable-next-line:no-console */
                onShareError: (error) => console.log(error)
              }}
              language = {shareLanguage}
              languageA = {this.state.language}
              text = {this.shareText()}
              showPopupHandler = {this.showPopupHandler}
            />
          </div>
          </div>
        
        </section>
      </div>
    );
  }
}

export default Products;
