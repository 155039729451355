import React from "react";
import webShare, { WebShareInterface } from 'react-web-share-api';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactGA from 'react-ga';

const Button = ({isSupported, share,text, languageA, language, showPopupHandler }) => {
  console.log(isSupported);
  
  return isSupported
    ? <button className = "share" onClick={() => {ReactGA.event({category: "share", action: "clicked"});share();}}>{language}</button>
    : <CopyToClipboard text={text + "\nhttps://www.mainbhiambani.com/#/" + (languageA === 'hindi' ? 'hindi' : '')} onCopy={this}>
        
        <button onClick={() => {ReactGA.event({category: "share", action: "clicked"});showPopupHandler('Link Copied!')} } className = "share">{language}</button>
      </CopyToClipboard>;
}
export default webShare()(Button);