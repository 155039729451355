var list = [
  {
    "name": "Chai",
    "hindiName": "चाय",
    "image": "Chai.jpg",
    "price": 10,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Samosa",
    "hindiName": "समोसा",
    "image": "Samosa.jpg",
    "price": 20,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Bottled Water",
    "hindiName": "पानी",
    "image": "Bottle.jpg",
    "price": 20,
    "subtitle": "Avoid dehydration while spending so much",
    "hindiSubtitle": "इतना खर्च करेंगे तो प्यास तोह लगेगी ही",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Biryani",
    "hindiName": "बिरयानी",
    "image": "Biryani.jpg",
    "price": 100,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 1000,
    "triggerQuantityError": "Looks like for someone biryani is BAE-ryani",
    "hindiTriggerQuantityError": "इतनी बिरयानी के लिए ट्रक लाना पड़ेगा"
  },
  {
    "name": "KRK",
    "hindiName": "क.र.क",
    "image": "Krk.jpg",
    "price": 2,
    "subtitle": "Real Khan of Bollywood",
    "hindiSubtitle": "बॉलीवुड का असली खान",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Donate a Meal",
    "hindiName": "अन्न दान",
    "image": "Thali.jpg",
    "price": 100,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 100,
    "triggerQuantityError": "A lot of people say that they don't have anything to give. Well, you thought about giving to others on the 1st opportunity you got. Well done :)",
    "hindiTriggerQuantityError": "बहुत सारे लोग कहते हैं कि उनके पास देने के लिए कुछ भी नहीं है। और आपने पहले अवसर पर दूसरों के लिए कुछ करने के बारे में सोचा, बहुत बढ़िया :)"
  },
  {
    "name": "10 GB 4g Pack",
    "hindiName": "10 जीबी डाटा पैक",
    "image": "Sim.jpg",
    "price": 200,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Wireless Headphone",
    "hindiName": "वायरलेस हेडफ़ोन",
    "image": "Headphone.jpg",
    "price": 7000,
    "subtitle": "Life is already pretty tangled, why have wired headphones",
    "hindiSubtitle": "जीवन पहले से ही बहुत पेचीदा है, क्यों हेडफ़ोन वायर वाले ले",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "10,000 PUBG UC",
    "hindiName": "10,000 पुबज  यूसी",
    "image": "PubgUC.jpg",
    "price": 10000,
    "subtitle": "You can buy Pubg actually",
    "hindiSubtitle": "आप वास्तव में पबजी खरीद सकते हैं",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 1000,
    "triggerQuantityError": "How much chicken dinner will you want to eat? Constipation ho jaayega",
    "hindiTriggerQuantityError": "आप कितना Chicken Dinner खाना चाहेंगे? कब्ज हो जायगा"
  },
  {
    "name": "Adidas Yeezy",
    "hindiName": "एडिडास यीज़ी जूता",
    "image": "Yeezy.jpg",
    "price": 25000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Laptop",
    "hindiName": "लैपटॉप",
    "image": "Laptop.jpg",
    "price": 30000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "4K LED TV",
    "hindiName": "4K एलईडी टीवी",
    "image": "TV.jpg",
    "price": 50000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "iPhone",
    "hindiName": "आईफोन मैक्स प्रो",
    "image": "Phone.jpg",
    "price": 70000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Bhai Ka Bracelet",
    "hindiName": "भाई का ब्रसलेट",
    "image": "Bracelet.jpg",
    "price": 80000,
    "subtitle": "Khan you not",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Scooty",
    "hindiName": "स्कूटी",
    "image": "Scooty.jpg",
    "price": 80000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Bullet",
    "hindiName": "बुलेट ",
    "image": "Bullet.jpg",
    "price": 200000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Nano",
    "hindiName": "नैनो ",
    "image": "Nano.jpg",
    "price": 300000,
    "subtitle": "Tata to Rickshaws",
    "hindiSubtitle": "अब रिक्शा को टाटा कहो",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Tractor",
    "hindiName": "ट्रैक्टर",
    "image": "Tractor.jpg",
    "price": 300000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Designer Bag",
    "hindiName": "ब्रांडेड हैंडबैग",
    "image": "Bag.jpg",
    "price": 350000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Supreme Hoodie",
    "hindiName": "सुप्रीम जैकेट",
    "image": "Supreme.jpg",
    "price": 350000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Sabysachi Lehenga",
    "hindiName": "सब्यसाची लेहेंगा",
    "image": "Lehenga.jpg",
    "price": 500000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Hublot",
    "hindiName": "हुबलोट",
    "image": "Hublot.jpg",
    "price": 1600000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Instagram Account",
    "hindiName": "इंस्टाग्राम अकाउंट",
    "image": "instagram.jpg",
    "price": 3500000,
    "subtitle": "With 1 Million Followers",
    "hindiSubtitle": "10 लाख फॉलोअर्स के साथ",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "347",
    "triggerQuantityError": "Instagram, on its own account doesn't have these many followers!",
    "hindiTriggerQuantityError": "इंस्टाग्राम के खुद के अकाउंट के पास इतने फोल्लोवेर्स नहीं हैं!"
  },
  {
    "name": "1 Kg Gold",
    "hindiName": "1 किलो सोना",
    "image": "Gold.jpg",
    "price": 4000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 100,
    "triggerQuantityError": "I think we have found the next Bappi Lahiri",
    "hindiTriggerQuantityError": "लगता है कि हमें अगला बप्पी लहरी मिल गया है"
  },
  {
    "name": "Arjun Kapoor Dancing at your Wedding",
    "hindiName": "शादी में अर्जुन कपूर का डांस",
    "image": "Arjun.jpg",
    "price": 5000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 10,
    "triggerQuantityError": "How much will you make him dance, don't you want to get married?",
    "hindiTriggerQuantityError": "आप उसे कितना डांस कराएंगे, क्या आप शादी नहीं करना चाहते?"
  },
  {
    "name": "Donate to CoronaVirus Research",
    "hindiName": "कोरोनाविरस रिसर्च को दान करें",
    "image": "CoronaVirus.jpg",
    "price": 5000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "World Tour",
    "hindiName": "विश्व यात्रा",
    "image": "WorldTour.jpg",
    "price": 10000000,
    "subtitle": "Add Wanderlust to your instabio now",
    "hindiSubtitle": "पर Facebook पे  डालने के लिए कोई चित्र नहीं मिला है",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Anuradha Laxmi Chit Fund",
    "hindiName": "अनुराधा लक्समी चिट फण्ड ",
    "image": "Anuradha.jpg",
    "price": 1000000,
    "subtitle": "Babu Rao is watching you...",
    "hindiSubtitle": "बाबू राव आपको देख रहे हैं ...",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Trip To Mars",
    "hindiName": "मंगल यात्रा",
    "image": "Mars.jpg",
    "price": 13000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 100,
    "triggerQuantityError": "What's up, Elon?",
    "hindiTriggerQuantityError": "इ.स.रो आपका स्थान जानना चाहता है"
  },
  {
    "name": "Mercedes",
    "hindiName": "मर्सिडीज",
    "image": "Mercedes.jpg",
    "price": 15000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Lambhorgini",
    "hindiName": "लेम्बोर्गिनी",
    "image": "Lambhorgini.jpg",
    "price": 30000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 5,
    "triggerQuantityError": "We know you purchased this because of that song",
    "hindiTriggerQuantityError": "हम जानते हैं कि आपने इसे उस गाने के कारण खरीदा है"
  },
  {
    "name": "Ferrari",
    "hindiName": "फेरारी",
    "image": "Ferrari.jpg",
    "price": 30000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Rolls Royce Ghost",
    "hindiName": "रोल्स रॉयस घोस्ट",
    "image": "RollsRoyce.jpg",
    "price": 45000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Pub",
    "hindiName": "पब",
    "image": "Pub.jpg",
    "price": 50000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "1 Acre Land",
    "hindiName": "1 एकड़ जमीन",
    "image": "Land.jpg",
    "price": 60000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "MF Hussain Painting",
    "hindiName": "एमएफ हुसैन चित्र",
    "image": "MF.jpg",
    "price": 80000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Majnu Bhai Painting",
    "hindiName": "मजनू भाई पेंटिंग",
    "image": "Majnu.jpg",
    "price": 80000001,
    "subtitle": "Apne bhai FM hussain se kam hain kya",
    "hindiSubtitle": "अपने भाई एफएम हुसैन से कम है क्या",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Tech Startup",
    "hindiName": "टेक व्यापार",
    "image": "Startup.jpg",
    "price": 100000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Building",
    "hindiName": "इमारत",
    "image": "Building.jpg",
    "price": 150000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": 10,
    "triggerQuantityError": "Lodha Builder is getting jealous of you",
    "hindiTriggerQuantityError": "लोधा बिल्डर को आपसे जलन हो रही है।"
  },
  {
    "name": "Bungalow in Mumbai",
    "hindiName": "मुंबई में बंगला",
    "image": "House.jpg",
    "price": 200000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Pro Kabbadi League",
    "hindiName": "प्रो कबड्डी लीग टीम",
    "image": "PKL.jpg",
    "price": 20000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 8,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Lokh Sabha MP Seat",
    "hindiName": "विधायक सीट",
    "image": "MLA.jpg",
    "price": 500000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 543,
    "quantity": 0,
    "triggerQuantity": 543,
    "triggerQuantityError": "There are 543 seats in the Lok Sabha, unfortunately you can't pre-book this. This ain't Amazon",
    "hindiTriggerQuantityError": "लोकसभा में 543 सीटें हैं, दुर्भाग्य से आप इसे पहले बुक नहीं कर सकते। यह अमेज़न नहीं है।"
  },
  {
    "name": "ISL Team",
    "hindiName": "ए.स.ल टीम ",
    "image": "ISL.jpg",
    "price": 500000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 10,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Produce Bollywood Movie",
    "hindiName": "हिंदी फिल्म",
    "image": "Bollywood.jpg",
    "price": 600000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Hospital",
    "hindiName": "अस्पताल",
    "image": "Hospital.jpg",
    "price": 1000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Helicopter",
    "hindiName": "हेलीकॉप्टर",
    "image": "Helicopter.jpg",
    "price": 1100000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "IPL Team",
    "hindiName": "आई.पी.एल टीम",
    "image": "IPL.jpg",
    "price": 5000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 8,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Rocket",
    "hindiName": "राकेट",
    "image": "Rocket.jpg",
    "price": 5000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Stadium",
    "hindiName": "स्टेडियम",
    "image": "Stadium.jpg",
    "price": 7500000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": "",
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Anil Ambani",
    "hindiName": "अनिल अंबानी",
    "image": "Anil.jpg",
    "price": 8000000000,
    "subtitle": "One can always 'Reli' on his brother",
    "hindiSubtitle": "",
    "maxQuantity": 1,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Vijay Mallya",
    "hindiName": "विजय माल्या",
    "image": "VijayMallya.jpg",
    "price": 90000000000,
    "subtitle": "We couldn't put alcohol so....",
    "hindiSubtitle": "हम शराब नहीं डाल सकते ....",
    "maxQuantity": 1,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Antila",
    "hindiName": "अंतिला",
    "image": "Antila.jpg",
    "price": 100000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 1,
    "quantity": 0,
    "triggerQuantity": 1,
    "triggerQuantityError": "Buying one's thing from onself? Smart",
    "hindiTriggerQuantityError": "खुद की चीज़ खुद से खरीदना, बढ़िया"
  },
  {
    "name": "Kohinoor",
    "hindiName": "कोहिनोर",
    "image": "Diamond.jpg",
    "price": 800000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 1,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  },
  {
    "name": "Mauritius",
    "hindiName": "मॉरीशस",
    "image": "Mauritius.jpg",
    "price": 1000000000000,
    "subtitle": "",
    "hindiSubtitle": "",
    "maxQuantity": 1,
    "quantity": 0,
    "triggerQuantity": "",
    "triggerQuantityError": "",
    "hindiTriggerQuantityError": ""
  }


]


export {list};